<template>
  <div :class="$style.layout">
    <global-header>
      <div slot="left" :class="$style.headerLeft">
        <div :class="$style.home">
          <template v-if="enterprise">
            <x-oss-image
              :class="$style.enterpriseLogo"
              :oss-path="
                enterprise.logo || require('@/assets/img/default-company.png')
              "
              :service="ossService"
            >
              <img slot="error" src="@/assets/img/default-company.png" alt="" />
            </x-oss-image>
            <span :class="$style.enterpriseName">{{
              enterprise.enterpriseShortName || enterprise.enterpriseName
            }}</span>
          </template>
          <template v-else>
            <!-- <x-icon type="tc-icon-triascloud" /> -->
            <img src="@/assets/img/logo.gif" />
            <!-- <logo /> -->
            <x-icon type="tc-icon-triascloud-zh" />
          </template>
        </div>
      </div>
      <div slot="right" :class="$style.headerRight">
        <theme-select :value="skin" @input="handleSkinChange" />
        <a-divider type="vertical" />
        <language-select :value="locale" @input="handleLocaleChange" />
        <user-menu :avatar="avatar" />
      </div>
    </global-header>
    <div :class="$style.content">
      <slot>
        <animation-background :class="$style.layoutBg" />
        <animation-logo :class="$style.logo" />
        <div :class="$style.pageWrapper">
          <router-view :pageStyle="$style" />
        </div>
      </slot>
    </div>
    <div :class="$style.footer" v-if="!enterprise">
      <p :class="$style.copyright">
        <a
          target="_blank"
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44011202002118"
        >
          <img src="@/assets/img/police-badge.png" />
          粤公网安备 44011202002118号
        </a>
        <a-divider type="vertical" />
        <a target="_blank" href="https://beian.miit.gov.cn">
          {{ $t('enterprise.copyright.number', { number: 19117127 }) }}
        </a>
        <a-divider type="vertical" />
        {{ $t('enterprise.copyright.name') }}©2019
        {{ $t('enterprise.copyright.owner') }}
        {{ $t('enterprise.copyright.tip') }}
      </p>
    </div>
  </div>
</template>
<script>
import { Component, Vue, Provide, Watch } from 'vue-property-decorator';
import { Mutation, namespace } from 'vuex-class';
import { crossStorage, CrossStorageEventType } from '@triascloud/cross-storage';
import { globalVueI18n } from '@triascloud/i18n';
import { ssoClient } from '@triascloud/sso-client';
import {
  LanguageSelect,
  ThemeSelect,
  UserMenu,
  Header as GlobalHeader,
} from '@triascloud/x-blocks';

import defaultAvatar from '@/assets/img/default-avatar.png';
// import Logo from '@/assets/img/logo.vue';
import { requestLogout } from '@/services/account';
import { setStorage, clearStorage } from '@/services/storage';
import { getEnterpriseByUrl } from '@/services/tenant';
import { AnimationBackground, AnimationLogo } from '@/components/animation';
import { createModuleUrl } from '@/utils';
import { getAuth } from '@/services/oss';

import { SSOContext } from './context';
import { deepClone } from '@triascloud/utils';

@Component({
  components: {
    GlobalHeader,
    AnimationBackground,
    AnimationLogo,
    LanguageSelect,
    ThemeSelect,
    UserMenu,
    // Logo,
  },
})
export default class IndexPage extends Vue {
  @namespace('crossStorage').State tenant;
  @namespace('crossStorage').State locale;
  @namespace('crossStorage').State skin;
  @namespace('crossStorage').Mutation setItem;
  @Mutation habitChange;

  /** @name 传递root */
  @Provide(SSOContext.Root)
  get root() {
    return this;
  }
  /** @name 传递公共样式给子组件 */
  @Provide(SSOContext.PageStyle)
  get pageStyle() {
    return this.$style;
  }

  /** @name 首页地址 */
  get home() {
    return createModuleUrl('app');
    // return '//localhost:8080';
  }
  get avatar () {
    return location.origin + defaultAvatar;
  }
  /** @name 图片组件替换请求接口 */
  ossService = { getAuth };
  /** @name 是否已经登录 */
  alreadyLogin = false;

  /** @name 是否需要关闭页面 */
  close = false;

  /** @name 要跳转的地址 */
  to = '';

  /** @name 企业邀请码 */
  inviteKey = '';

  /** @name 当前企业id */
  currentTenantId = '';

  /** @name 第三方账号绑定信息 */
  authBindPayload = null;

  /**@name 语言切换 */
  handleLocaleChange(value) {
    this.habitChange('locale');
    this.setItem({
      key: 'locale',
      value,
    });
  }
  @Watch('locale')
  setLocale(value) {
    document.documentElement.lang = this.locale.replace('_', '-');
    globalVueI18n.locale(value);
  }

  handleSkinChange(value) {
    this.habitChange('skin');
    this.setItem({
      key: 'skin',
      value: JSON.stringify(value),
    });
  }
  @Watch('skin')
  setTheme(value) {
    ThemeSelect.setTheme(document.documentElement, value);
  }

  created() {
    crossStorage.serverEmitter.on(
      CrossStorageEventType.Update,
      this.handleStorageChange,
    );
    // 记录是否需要关闭页面
    this.close = 'close' in this.$route.query && !!window.opener;
    // 记录跳转地址
    this.to = this.$route.query.to || this.home;
    // 记录企业邀请码
    this.inviteKey = this.$route.query.k || '';
    // 记录当前企业id
    this.currentTenantId = this.$route.query.t;
    // 初始化自定义登录页面
    this.initCustomPage();
    this.alreadyLogin = !!ssoClient.getTokenSync();
    if (!this.alreadyLogin) return;
    // 如果是已登录状态
    if (this.$route.query.logout) {
      // 登出
      this.alreadyLogin = false;
      this.handleLogout();
    }
  }

  beforeDestroy() {
    crossStorage.serverEmitter.off(
      CrossStorageEventType.Update,
      this.handleStorageChange,
    );
  }

  handleStorageChange(data) {
    if (!data || data.key !== 'storageLoaded') return;
    this.redirect(true);
  }

  get redirectUrl() {
    return this.to.startsWith(createModuleUrl('account')) ? this.home : this.to;
  }

  redirect(force = false) {
    if (!force && !crossStorage.getItem('tenant')) {
      this.$router.push('/select');
    } else {
      try {
        if (this.close && window.opener) {
          window.close();
        }
      } finally {
        window.location.replace(this.redirectUrl);
      }
    }
  }
  setStorage(token, rememberMe = false) {
    return setStorage(token, rememberMe);
  }
  async handleLogout() {
    this.currentTenantId = this.tenant?.pkId;
    if (this.tenant?.customLoginUrl) {
      this.enterprise = deepClone(this.tenant);
      this.$route.path !== `/e/${this.enterprise.customLoginUrl}` && this.$router.replace(`/e/${this.enterprise.customLoginUrl}`);
    }
    try {
      await requestLogout();
    } finally {
      clearStorage();
      this.$message.success(this.$t('login.logoutSuccess'));
    }
  }

  /** 自定义登录页面的企业信息 */
  enterprise = null;
  async initCustomPage() {
    if ((this.$route.name === 'customLoginPage' && this.$route.params.customUrl) || this.currentTenantId) {
      const enterprise = await getEnterpriseByUrl(this.$route.params.customUrl ? { url: this.$route.params.customUrl } : { pkId: this.currentTenantId });
      if (enterprise && enterprise.customLoginUrl) {
        this.enterprise = enterprise;
        this.currentTenantId = enterprise.pkId;
        this.$router.replace(`/e/${enterprise.customLoginUrl}`);
      }
    }
  }
}
</script>

<style lang="less" module>
.layout {
  min-width: 800px;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.logo {
  margin-right: 100px;
}
@media screen and (max-width: 1000px) {
  .logo {
    display: none;
  }
}
.content {
  position: relative;
  padding-bottom: 40px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.header {
  height: 56px;
  line-height: 56px;
  box-shadow: 0px 10px 39px 10px var(--header-shadow);
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
:global(.x-header) .home {
  width: auto;
  display: flex;
  align-items: center;
  height: 100%;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 2px;
  margin-left: 4px;
  > :global(.anticon) {
    font-size: 80px;
    margin-right: 16px;
  }
  color: var(--font);
  &:hover {
    color: var(--font);
  }
  .enterpriseLogo {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    margin-right: 10px;
  }
}
.headerLeft {
  padding: 0 30px;
  img {
    width: 36px;
    object-fit: contain;
    margin-right: 10px;
  }
}
.headerRight {
  display: flex;
  align-items: center;
}
.footer {
  height: 40px;
  line-height: 40px;
  background-color: transparent;
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0;
}
.copyright {
  color: var(--font-info);
  font-size: 12px;
  margin: 0;
  position: relative;
  a {
    color: inherit;
    text-decoration: underline;
    transition: none;
  }
  :global(.ant-divider) {
    background-color: currentColor;
  }
}
.pageWrapper {
  flex-basis: 730px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.page {
  position: relative;
  width: 500px;
  border-radius: 10px;
  background-color: var(--block-bg);
  overflow: hidden;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 60px 0 var(--shadow);
}
:global(.tint) .page {
  box-shadow: 0 0 60px 0 var(--primary-fade-8);
}
.pageHeader {
  height: 60px;
  line-height: 60px;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: var(--font-info);
  background-color: var(--modal-header-bg);
  border-radius: 10px 10px 0 0;
}
.pageBody {
  padding: 25px 50px;
  overflow-y: auto;
  :global {
    .ant-form-item {
      margin-bottom: 16px;
    }
    .ant-form-item-label {
      line-height: 32px;
    }
    .ant-input {
      height: 40px;
    }
    .ant-form-explain {
      position: absolute;
      font-size: 12px;
      width: 100%;
    }
    .x-input {
      height: 40px;
      line-height: 40px;
    }
  }
}
.pageFooter {
  color: var(--font-info);
  height: 60px;
  padding: 0 20px;
  display: flex;
  align-items: center;
}
.verifyCode :global {
  .ant-form-item-children {
    display: flex;
  }
  .ant-input {
    flex: 1;
  }
  .ant-btn {
    width: calc(50% - 5px);
    margin-left: 5px;
    height: auto;
    letter-spacing: 1px;
  }
}
</style>
