import Vue from 'vue';
import { BaseService } from '@triascloud/services';
import { crossStorage, CrossStorageWeb } from '@triascloud/cross-storage';
import { ssoClient, SSOClientWeb } from '@triascloud/sso-client';
import { globalVueI18n, ensureLocale } from '@triascloud/i18n';
import XComponents from '@triascloud/x-components';
import { ExpiredLogin } from '@triascloud/x-blocks';

import '@triascloud/ant-design-theme/dist/index.css';
import '@triascloud/x-components/dist/index.css';
import '@triascloud/x-blocks/dist/index.css';
import './global.less';

import App from './App.vue';
import router from './router';
import store from './store';
import request from './services/request';

BaseService.request = request;
Vue.config.productionTip = false;
Vue.use(XComponents, {
  scriptUrl: '//at.alicdn.com/t/font_2003313_snb073trm7b.js',
});

ssoClient.init(
  new SSOClientWeb({
    server: location.origin,
    requestExpiredLogin: () => ExpiredLogin.create(location.origin),
  }),
);

crossStorage
  .init(new CrossStorageWeb(`${location.origin}/storage.html`))
  .then(data => {
    const defaultLocale = ensureLocale(data.locale);
    globalVueI18n.fetchModule(defaultLocale, 'package');
    Vue.use(globalVueI18n, {
      defaultLocale,
      defaultModule: 'sso',
    });
    new Vue({
      router,
      store,
      render: h => h(App),
    }).$mount('#app');
  });
