import { ensureArray } from '@triascloud/utils';

export function isLocalhost(origin) {
  return /^https?:\/\/localhost(:\d{1,5})?\/?$/.test(origin);
}

export function isSafari() {
  return navigator.vendor === 'Apple Computer, Inc.';
}

export { createModuleUrl } from './createModuleUrl';

/**
 * @name 异步验证指定表单字段
 * @param { import('ant-design-vue').FormModel } form
 * @param { string | string [] } fields
 * @return { Promise<string[]> }
 */
export function validateFieldAsync(form, fields) {
  return Promise.all(
    ensureArray(fields).map(
      field =>
        new Promise((resolve, reject) =>
          form.validateField(field, err =>
            err ? reject('form validate error') : resolve(field),
          ),
        ),
    ),
  );
}

export const originWhileList = process.env.VUE_APP_ORIGIN_WHITE_LIST
  ? process.env.VUE_APP_ORIGIN_WHITE_LIST.split(',')
  : [];
