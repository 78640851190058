<template>
  <div :class="[pageStyle.page, $style.login]">
    <div :class="$style.main">
      <a-tabs :active-key="activeKey" :class="$style.tab">
        <a-tab-pane v-for="tab in tabs" :key="tab.path">
          <router-link
            v-if="!tab.developing"
            :class="$style.link"
            :to="tab.path"
            slot="tab"
          >
            <x-icon :type="tab.icon" />
            <span>{{ tab.name }}</span>
          </router-link>
          <span v-else :class="$style.link" slot="tab" @click="developing">
            <x-icon :type="tab.icon" />
            <span>{{ tab.name }}</span>
          </span>
        </a-tab-pane>
      </a-tabs>
      <keep-alive>
        <router-view :class="$style.router" />
      </keep-alive>
    </div>
    <div :class="$style.other">
      <h4>{{ $t('login.noAccount') }}：</h4>
      <router-link :class="$style.register" to="/register">
        <span>{{ $t('login.register') }}</span>
        <x-icon type="tc-icon-arrow-right-filled" />
      </router-link>
      <h5>{{ $t('login.otherAccounts') }}</h5>
      <div :class="$style.thirdParty">
        <x-icon
          @click="openThirdParty('wechatopen')"
          type="tc-icon-wechat-filled"
          theme="filled"
          :class="$style.wechat"
        />
        <x-icon
          @click="openThirdParty('qq')"
          type="qq-circle"
          theme="filled"
          :class="$style.QQ"
        />
        <x-icon
          @click="openThirdParty('alipay')"
          type="alipay-circle"
          theme="filled"
          :class="$style.alipay"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { Component, Vue, Inject, Provide, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { parse } from '@triascloud/utils';
import { createModuleUrl } from '@/utils';
import Oauth from '@/utils/oauth';
import { authLogin } from '@/services/oauth';
import { SSOContext } from '../context';

@Component()
export default class LoginPage extends Vue {
  @namespace('crossStorage').Mutation setItem;
  @Inject(SSOContext.Root) root;
  @Inject(SSOContext.PageStyle) pageStyle;
  @Provide(SSOContext.FormStyle)
  get formStyle() {
    return this.$style;
  }
  get activeKey() {
    return this.$route.path === '/email' ? '/phone' : this.$route.path;
  }
  get callbackOrigin() {
    return createModuleUrl('account');
  }

  get tabs() {
    return [
      { name: this.$t('login.login'), path: this.customUrl ? `/e/${this.customUrl}` : '/' , icon: 'tc-icon-lock' },
      {
        name: this.$t('login.codeLogin'),
        path: '/phone',
        icon: 'tc-icon-mail',
      },
      {
        name: this.$t('login.dingtalkLogin'),
        path: '/dingtalk',
        icon: 'tc-icon-dingtalk-filled',
      },
      // {
      //   name: this.$t('login.enterpriseWeChat'),
      //   path: '/wxwork',
      //   icon: 'wechat',
      // },
    ];
  }

  oauth = new Oauth();

  developing() {
    this.$message.info('该功能暂未开放，敬请期待！');
  }
  customUrl = '';
  @Watch('$route.params.customUrl', { immediate: true })
  refreshCustomUrl(value) {
    if (value) {
      this.customUrl = value;
    }
  }
  created() {
    // 已经登录的情况自动跳转
    if (this.root.alreadyLogin) {
      this.root.redirect();
    }
  }

  openThirdParty(type) {
    this.oauth.toAuthLogin(type);
    window.addEventListener('message', this.handleMessage);
  }

  async handleMessage(ev) {
    if (
      ev.origin === this.callbackOrigin &&
      ev.data &&
      ev.data.key === 'sso-login-callback'
    ) {
      const params = parse((ev.data.value || '').replace(/^\?/, ''));
      try {
        // 参数区分 1.支付宝：auth_code 2.微信、QQ:code
        const auth_code = params.type === 'alipay' ? 'auth_code' : 'code';
        const token = await authLogin(params.type, {
          state: params.state,
          [auth_code]: params[auth_code],
        });
        await this.root.setStorage(token, true);
        this.$message.success(this.$t('login.loginSuccess'));
        this.root.redirect();
      } catch (error) {
        // 当用户没有进行绑定的时候，无法凭code换取登录态，后端将会返回uuid
        // 用于接下来的登录/注册
        if (error.data) {
          this.root.authBindPayload = { type: params.type, uuid: error.data };
          this.$router.push('/auth-bind/account');
        }
      }
      window.removeEventListener('message', this.handleMessage);
    }
  }
}
</script>
<style lang="less" module>
div.login {
  min-width: 730px;
  min-height: 450px;
  padding: 35px 70px 35px 72px;
  flex-direction: row;
  :global(.ant-checkbox-wrapper) {
    font-size: 12px;
    color: var(--font-info);
  }
}
.main {
  flex: 1;
}
.router {
  margin-right: 45px;
}
.other {
  width: 180px;
  align-self: flex-start;
  margin-top: 100px;
  padding-left: 25px;
  padding-top: 20px;
  border-left: 1px solid var(--border);
  :global {
    h4 {
      font-size: 12px;
      font-weight: normal;
      color: var(--font-info);
      margin-bottom: 18px;
    }
    h5 {
      font-size: 12px;
      color: var(--font-sub);
      margin-bottom: 20px;
    }
  }
}
.register {
  display: flex;
  align-items: center;
  margin-bottom: 90px;
  :global(.anticon) {
    margin-left: 10px;
    font-size: 16px;
  }
}
.thirdParty {
  display: flex;
  justify-content: space-around;
  .wechat {
    color: #00c900;
  }
  .QQ {
    color: #30a5dd;
  }
  .alipay {
    color: #06b4fd;
  }
  :global(.anticon) {
    font-size: 22px;
    cursor: pointer;
  }
}
.tab :global {
  color: var(--font-info);
  .ant-tabs-nav .ant-tabs-tab {
    padding: 0;
    margin-right: 40px;
    height: 40px;
    line-height: 40px;
    .anticon {
      font-size: 16px;
      margin-right: 5px;
    }
  }
  .ant-tabs-bar {
    margin-bottom: 20px;
  }
}
.link {
  display: block;
  &:not(:global(.router-link-exact-active)) {
    color: inherit;
  }
}
.form {
  margin-top: 40px;
  .captcha :global(.ant-input-suffix) {
    right: 5px;
    > img {
      width: 70px;
      height: 30px;
      border: 1px solid var(--border);
      cursor: pointer;
    }
  }
  :global {
    .x-input {
      height: 40px;
      line-height: 40px;
    }
    .ant-form-item {
      margin-bottom: 36px;
    }
    .ant-input {
      height: 40px;
      &::placeholder {
        color: #ccc;
      }
    }
    .ant-btn-primary {
      height: 40px;
    }
    .ant-input-affix-wrapper .ant-input:not(:first-child) {
      padding-left: 40px;
    }
    .ant-input-prefix,
    .ant-input-suffix {
      .anticon {
        font-size: 16px;
        color: #ccc;
      }
    }
    .ant-form-explain {
      position: absolute;
      font-size: 12px;
    }
  }
}
</style>
