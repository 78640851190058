import baseRequest from '@triascloud/request';
import { message } from 'ant-design-vue';
import { ssoClient } from '@triascloud/sso-client';
import { createModuleUrl } from '@/utils';

/** @type { typeof baseRequest } */
export default async function request(relativeUrl, options = {}) {
  const absoluteUrl = relativeUrl.startsWith('/')
    ? createModuleUrl('api', 'https:') + relativeUrl
    : relativeUrl;
  const headers = { ...options.headers };
  if (options.auth !== false) {
    headers['X-Access-Token'] = await ssoClient.getToken();
  }
  return baseRequest(absoluteUrl, {
    ...options,
    headers,
  }).catch(error => {
    if (options.showError !== false) {
      const errorMessage =
        (error && error.message) || error.errmsg || '未知错误';
      message.error(errorMessage);
    }
    throw error;
  });
}
